@use '/node_modules/@rocketcentral/rocket-design-system-styles/web/scss/_color.scss' as *;

$primary: $rkt-red-500;
$link-color: $rkt-purple-bright-500;
$info: $rkt-gray-800;
$input-height: calc(1.5em + 0.75rem + 4px);

// Load rocket material theme from styles.
@use "/node_modules/@rocketcentral/rocket-design-system-styles/theme-angular" as *;

// Load rocket custom component styles from styles.
@use "/node_modules/@rocketcentral/rocket-design-system-styles/rocket" as *;

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: "rocket-sans";
  src: url("https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Bold.woff2")
      format("woff2"),
    url("https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Bold.woff")
      format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "rocket-sans";
  src: url("https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Medium.woff2")
      format("woff2"),
    url("https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Medium.woff")
      format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "rocket-sans";
  src: url("https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Regular.woff2")
      format("woff2"),
    url("https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Regular.woff")
      format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "rocket-sans";
  src: url("https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Light.woff2")
      format("woff2"),
    url("https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Fonts/RocketSans-Light.woff")
      format("woff");
  font-weight: 300;
}

body,
app-root {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.fc-right .fc-prev-button,
.fc-right .fc-next-button,
.fc-button-primary {
  color: #fff;
  background-color: $primary;
  border-color: #166983;
}

.required-field::before {
  content: "*";
}

.ag-header-row {
  background-color: $rkt-gray-800;
  color: white;
}

.ag-header-cell-text {
  color: white;
}

.ag-root-wrapper-body {
  border-right: solid 1px;
  border-left: solid 1px;
  border-color: #bdc3c7;
}

.ag-theme-balham .ag-root-wrapper {
  border: none !important;
}

app-date-picker-form-group
  .input-group
  > .input-group-append:not(:last-child)
  > .btn {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.was-validated app-date-picker-form-group .custom-select:valid {
  border-color: #ced4da;
  padding-right: 0.5rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
}

.was-validated app-date-picker-form-group .custom-select:valid:focus {
  border-color: #141b21;
}

.fc-col-header, .fc-daygrid-body, .fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-scroller {
  overflow: visible !important;
}

.fc-daygrid-body {
  tr,
  .fc-daygrid-day-frame {
    height: 135px;
  }
}

.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end {
  background-color: $rkt-purple-bright-500;
  border-color: $rkt-purple-bright-500;
  color: $white;
}

.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end:hover
  .fc-event-title {
  white-space: normal;
}

.fc-today-button {
  text-transform: capitalize;
}

.ag-theme-balham .ag-icon {
  color: white;
}

.ag-theme-balham .ag-paging-page-summary-panel .ag-icon {
  color: $rkt-gray-800;
}

.accordion {
  .card-header {
    text-decoration: none;
    font-weight: bold;
    text-align: left;
    color: $rkt-purple-bright-500 !important;

    &:after {
      content: "\25BE" !important;
    }
  }

  .card-header.collapsed {
    &:after {
      content: "\25B8" !important;
    }
  }
}

.fc-button-primary,
.btn-outline-primary:hover,
.btn-light.bg-primary,
.btn-secondary:hover {
  background-color: $rkt-purple-bright-500 !important;
  border-color: $rkt-purple-bright-500 !important;
}

.fc-button-primary:disabled {
  background-color: #8d8d8c !important;
  border-color: #8d8d8c !important;
  opacity: 1;
}

.btn-outline-primary,
.btn-outline-primary:disabled,
.btn-secondary {
  color: $rkt-purple-bright-500;
  border-color: $rkt-purple-bright-500;
}

.btn {
  border-width: 2px;
}

.btn-secondary {
  background-color: white;
}

.btn-link {
  color: $rkt-gray-800;
}

.custom-file-label::after {
  height: auto;
}

.nav-link {
  color: $rkt-gray-800 !important;
}

.nav-link:hover,
.nav-link.active {
  text-decoration: underline;
  text-underline-position: under;
}

#launchKitStaticComponent-footerBasicInternal .footerBasicInternal-content {
  max-width: 1440px !important;
}
